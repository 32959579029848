import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  disabled?: boolean
  onClick?: (e: any) => void
  className?: string
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  disabled,
  onClick,
  className,
}: Props) {
  return (
    <Container
      dial={5}
      onClick={onClick}
      className={`${className} ${disabled ? 'disabled' : undefined}`}
    >
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;

  svg {
    width: auto;
    height: 0.75rem;
    transition: all 200ms linear;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  @media (min-width: 992px) {
    &:hover svg {
      opacity: 0.5;
    }
  }

  @media (max-width: 991px) {
    width: 48px;
    height: 48px;
  }

  &.disabled {
    pointer-events: none;
  }
`
