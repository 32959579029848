import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  pretitle?: string
  title?: string
  description?: string
  image?: ImageProps
  date?: Date
  category?: string
  slugMagazine?: string
  languageCode: string
}

export const IntroArticle = memo(function IntroArticle({
  title,
  description,
  image,
  date,
  category,
  slugMagazine,
  languageCode,
}: Props) {
  const itemDate = date ? new Date(date) : undefined || undefined

  const printCategory = (p_category: string) => {
    switch (p_category) {
      case 'food':
        return 'Food & Drink'
      case 'events':
        return useVocabularyData('events', languageCode)
      case 'services':
        return useVocabularyData('services', languageCode)
      case 'territory':
        return useVocabularyData('territory', languageCode)
      case 'sport':
        return useVocabularyData('sport-nature', languageCode)
    }
  }
  return (
    <Container>
      <WrapperTop>
        <BackToLink to={`/${slugMagazine}`}>
          <img src="/icon_arrow-dark.svg" alt="arrow" />
          Tutti gli articoli
        </BackToLink>
        <FadeInUp>
          <TextCenter>
            {itemDate && date && category ? (
              <Pretitle>
                {itemDate.getDate() +
                  '/' +
                  (itemDate.getMonth() + 1) +
                  '/' +
                  itemDate.getFullYear()}{' '}
                - {printCategory(category)}
              </Pretitle>
            ) : null}
            {title ? (
              <TitleCenter dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
          </TextCenter>
        </FadeInUp>

        <FadeInUp>
          <StyledImage {...image} />
        </FadeInUp>
      </WrapperTop>
      <WrapperBottom>
        {description ? (
          <FadeInUp>
            <DescriptionCenter
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </FadeInUp>
        ) : null}
      </WrapperBottom>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin-bottom: 6vw;
`

const WrapperTop = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 6.66vw;
  padding-top: calc(6.66vw + 70px);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
    padding-top: 120px;
  }
`

const BackToLink = styled(Link)`
  position: absolute;
  top: 100px;
  left: 6.66vw;
  font-size: 0.9vw;

  img {
    transform: rotate(180deg);
    margin-right: 15px;
    vertical-align: middle;
  }

  @media (max-width: 1439px) {
    font-size: 13px;
  }

  @media (max-width: 991px) {
    left: 30px;
    top: 80px;
  }
`

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`

const TitleCenter = styled.h2`
  font-size: 3vw;
  margin: 2vw 0 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    margin: 20px;
    font-size: 28px;
  }
`
const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  display: block;
  margin-top: 4vw;

  img {
    width: 100%;
    height: auto;
  }
`

const WrapperBottom = styled.div`
  padding: 6vw 20vw;
  padding-bottom: 0;

  @media (max-width: 1199px) {
    padding-left: 15vw;
    padding-right: 15vw;
  }

  @media (max-width: 991px) {
    padding: 30px;
  }
`
const TextCenter = styled.div`
  text-align: center;
  padding: 0 24vw;

  @media (max-width: 991px) {
    padding: 0;
  }
`

const DescriptionCenter = styled.div`
  p {
    font-size: 17px;
    line-height: 26px;
  }
`
