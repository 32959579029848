import * as React from 'react'
import { memo } from 'react'
const SvgCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15.298 10.724"
  >
    <path d="M13.579.788 6.072 8.469 1.754 4.648a.711.711 0 0 0-.942 1.065l4.825 4.269a.711.711 0 0 0 .98-.035l7.979-8.166a.711.711 0 0 0-1.017-.994Z" />
  </svg>
)
const Memo = memo(SvgCheck)
export default Memo
