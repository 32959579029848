import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  title?: string
  description?: string
  image?: ImageProps
}

export const Paragraph = memo(function Paragraph({
  title,
  description,
  image,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <FadeInUp>
          <Text>
            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </Text>
        </FadeInUp>
        {image ? (
          <FadeInUp>
            <StyledImage {...image} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.66vw 0;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    margin: 30px 0;
  }
`

const Wrapper = styled.div``

const Text = styled.div`
  padding: 0 30px;
  border-left: 1px solid ${({ theme }) => theme.colors.variants.neutralLight2};
`

const Title = styled.h3`
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 24px;
  text-transform: uppercase;
`

const Description = styled.div``

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  display: block;
  margin-top: 6vw;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`
