import * as React from 'react'
import { memo } from 'react'
const SvgChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.17"
    height="11.998"
    viewBox="0 0 8.17 11.998"
  >
    <path
      id="Tracciato_1739"
      data-name="Tracciato 1739"
      d="M108.23,144.37l-8.17,6.015,8.137,5.983a21.913,21.913,0,0,1-1.346-6,21.492,21.492,0,0,1,1.38-6"
      transform="translate(-100.06 -144.37)"
    />
  </svg>
)
const Memo = memo(SvgChevronLeft)
export default Memo
