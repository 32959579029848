import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  isPrevious?: boolean
  image?: ImageProps
  title?: string
  category?: string
  URL: string
  variant?: Variant
  layout?: string
  languageCode: string
}

export const Item = memo(function Item({
  isPrevious,
  image,
  title,
  category,
  URL,
  variant = 'default',
  languageCode,
}: Props) {
  if (!URL) {
    return null
  }

  const categoryLabel = () => {
    switch (category) {
      case 'food':
        return 'Food & Drink'
      case 'events':
        return useVocabularyData('events', languageCode)
      case 'services':
        return useVocabularyData('services', languageCode)
      case 'territory':
        return useVocabularyData('territory', languageCode)
      case 'sport':
        return useVocabularyData('sport-nature', languageCode)
    }
  }

  return (
    <Container to={URL}>
      <ContainerEl>
        {image ? (
          <LazyLoadComponent threshold={150}>
            <StyledImage {...image} />
          </LazyLoadComponent>
        ) : null}

        <Text>
          <Direction>{isPrevious ? 'PREVIOUS' : 'NEXT'}</Direction>
          {category ? <Category>{categoryLabel()}</Category> : null}
          {title ? (
            <Title
              variant={variant}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}
          <StyledArrow direction="R" />
        </Text>
      </ContainerEl>
    </Container>
  )
})

const Container = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 28vw;
`

const Text = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 6.66vw;
  z-index: 2;

  &::after,
  &::before {
    display: block;
    content: ' ';
    width: calc(100% - 13.2vw);
    height: 1px;
    position: absolute;
    bottom: 5.8vw;
    left: 6.66vw;
    opacity: 0.5;
    z-index: 2;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  &::after {
    width: 0;
    opacity: 1;
    transition: all 800ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`

const StyledArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 6.66vw;
  background: none;
  transform: translate3d(50%, -50%, 0);
`

const ContainerEl = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &::before {
    width: 100%;
    height: 100%;
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.5;
  }

  &:hover {
    img {
      transform: translate3d(-50%, -50%, 0) scale(1.1);
    }

    ${Text} {
      &::after {
        width: calc(100% - 10vw);
      }
    }
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate3d(-50%, -50%, 0);
    transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.5;
    transition: width 0.45s ease-in-out, height 0.45s ease-in-out,
      margin 0.45s ease-in-out;
  }
`

const Direction = styled.p`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const Category = styled.p`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const Title = styled.div<ContainerProps>`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.4vw;
  line-height: 1.5;
  text-transform: uppercase;
  padding-right: 1vw;
  max-width: 70%;
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'first'
