import * as React from 'react'
import { memo } from 'react'
const SvgChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.171"
    height="12"
    viewBox="0 0 8.171 12"
  >
    <path
      id="Tracciato_1739"
      data-name="Tracciato 1739"
      d="M100.06,144.37l8.171,6.016-8.138,5.984a21.917,21.917,0,0,0,1.347-6,21.5,21.5,0,0,0-1.38-6"
      transform="translate(-100.06 -144.37)"
    />
  </svg>
)
const Memo = memo(SvgChevronRight)
export default Memo
