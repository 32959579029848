import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  name?: string
  role?: string
  image?: ImageProps
  bio?: string
}

export const EditorInfos = memo(function EditorInfos({
  name,
  image,
  bio,
  role,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Pretitle>Editor</Pretitle>
        {image ? <EditorImage {...image} /> : null}
        {name ? <Name>{name}</Name> : null}
        {role ? <Role>{role}</Role> : null}
        {bio ? <Bio>{bio}</Bio> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.66vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`

const Wrapper = styled.div`
  text-align: center;
`

const Pretitle = styled.div`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`

const EditorImage = styled(Image)`
  width: 9vw;
  height: 9vw;
  margin: 1vw auto;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
  }
`

const Name = styled.div`
  font-size: 2.2vw;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`

const Role = styled.div`
  font-weight: 300;
`

const Bio = styled.p`
  margin-top: 2vw;
`
