import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item } from './item'

export interface Props {
  prevArticle?: any
  nextArticle?: any
  languageCode?: string
}

export const ArticlesExtra = memo(function ArticlesExtra({
  prevArticle,
  nextArticle,
  languageCode = 'it',
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Item languageCode={languageCode} isPrevious={true} {...prevArticle} />
        <Item languageCode={languageCode} isPrevious={false} {...nextArticle} />
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 991px) {
    margin-top: 60px;
  }
`
