import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Paragraph, Props as ParagraphProps } from './item'

export interface Props {
  paragraphList?: ParagraphProps[]
}

export const ParagraphList = memo(function ParagraphList({
  paragraphList,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {paragraphList?.map((item, index) => (
          <Paragraph key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 0 20vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 1199px) {
    padding: 0 15vw;
  }

  @media (max-width: 991px) {
    padding: 0 30px;
  }
`

const Wrapper = styled.div``
